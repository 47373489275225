
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import keycloakConfig from '../../../environments/keycloak.config';

export function initializer(keycloak: KeycloakService): () => Promise<boolean> {

    const options: KeycloakOptions = {
      config : keycloakConfig,
      loadUserProfileAtStartUp: true,
      initOptions: {
          onLoad: 'check-sso',
          // onLoad: 'login-required',
          checkLoginIframe: false,
          silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      },
      // shouldAddToken: (request) => {
      //   const { method, url } = request;
    
      //   const isGetRequest = 'GET' === method.toUpperCase();
      //   const acceptablePaths = ['/assets', '/clients/public'];
      //   // const isAcceptablePathMatch = urls.some((path) => url.includes(path));
    
      //   // return !(isGetRequest && isAcceptablePathMatch);
      //   return !(isGetRequest)
      // },
      bearerExcludedUrls: []
      
    };

    return () => keycloak.init(options);
}