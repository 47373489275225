import { INavData } from '@coreui/angular';
import { DashboardTilesService } from './../../views/dashboard-tiles/dashboard-tiles.service';
import { Component, OnInit, } from '@angular/core';



@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css'],
  providers:[DashboardTilesService]
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  constructor (private service: DashboardTilesService) {}
  navItems: INavData[]
  ngOnInit(): void {

    this.service.getdashboard().subscribe(response => {
      // var db= response.dashboard
      // if (db.some(a => a.name == 'Tenants')) {
      //   var i=db.findIndex(a => a.name == 'Tenants')
      //   delete db[i]['children']
      // }
      // if (db.some(a => a.name == 'Finance')) {
      //   var i=db.findIndex(a => a.name == 'Finance')
      //   delete db[i]['children']
      // }

      // if (db.some(a => a.name == 'Settings')) {
      //   var i=db.findIndex(a => a.name == 'Settings')
      //   delete db[i]['children']

      // }
      this.navItems = response.dashboard
   
    })
  }
  
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}

