import { NgModule,APP_INITIALIZER } from '@angular/core';
import {TokenInterceptor} from './token.interceptor'
import {AuthGuard} from './auth.guard'
import {initializer} from './keycloak-initializer'
import { AuthService } from './service/auth.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    KeycloakAngularModule
  ],
  providers:[
    {
      provide: APP_INITIALIZER,
      useFactory:initializer,
      multi:true,
      deps:[KeycloakService] 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuard,
    AuthService
  ]
})
export class AuthModule { }
