import {  BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { INavData } from '@coreui/angular';
// import dashboard from '../../../assets/dashboard.json'
import { HttpClient } from '@angular/common/http';


export class Config {
  constructor(
  public messages: {'welcome': string,'alert':string,'notifications':string},
  public GroupName: string,
  public dashboard: INavData[]) {}
}

@Injectable({
  providedIn: 'root'
})

export class DashboardTilesService {
  
  private dashboardurl = '/core/v1/Dashboard'

  constructor(private http: HttpClient) {}

  getdashboard(): Observable<Config> {
    return this.http.get<Config>(this.dashboardurl)
  }

  dash = new BehaviorSubject<Config>(null);
  currentdash = this.dash.asObservable();


  setdash(dash: Config) {
    this.dash.next(dash);
  }

  // getnavitems() {
    
  //   // return dashboard.dashboard
  //   // console.log('getting result from core dashboard')
  //   // const req = this.http.get(this.dashboardurl,{ observe: 'response',reportProgress:true })
 
    
  //   return this.navItems
  //   }
}
