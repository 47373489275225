<app-header
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/New-GTLogo(2).png', width: 200, height: 54, alt: 'Getraind'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/favicon-2.png', width: 60, height: 54, alt: 'Getraind'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="'lg'">

  <cui-breadcrumb >
     
  </cui-breadcrumb>
  <ul class="nav navbar-nav ml-auto">
    
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <img src="assets/img/avatars/User-avatar.svg.png" class="img-avatar" alt="admin@bootstrapmaster.com"/>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <a class="dropdown-item" href="https://portal.getraind.com/auth/realms/Getraind/protocol/openid-connect/logout?redirect_uri=https://portal.getraind.com/"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <!-- <cui-breadcrumb>
     
    </cui-breadcrumb> -->
    
    <div class="container-fluid">
      <br>
       <router-outlet></router-outlet>
      <!-- <app-dashboard-tiles></app-dashboard-tiles> -->
    </div><!-- /.container-fluid -->
  </main>
  
</div>
<app-footer>
  <span><a href="https://portal.getraind.com/">Getraind</a> &copy; 2022</span>
  <span class="ml-auto">For Support Issues reach <a href="https://portal.getraind.com">support@getraind.com</a></span>
</app-footer>
