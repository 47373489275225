import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://portal.getraind.com/auth',
  realm: 'Getraind',
  clientId: 'portal',
};

// const keycloakConfig: KeycloakConfig = {
//   url: 'https://portal.getraind.com/auth',
//   realm: 'Getraind',
//   clientId: 'coreApp',
// };

export default keycloakConfig;