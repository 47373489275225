import { Component, OnInit } from '@angular/core';
import { NavigationCancel,NavigationError,NavigationStart,Router, NavigationEnd } from '@angular/router';
import { LoaderService } from './loader/loader.service';
import { IconSetService } from '@coreui/icons-angular';
import { cilUser } from '@coreui/icons';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: `<app-loader></app-loader><router-outlet></router-outlet>`,
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    public iconSet: IconSetService,
    private loader: LoaderService
  ) {
    // iconSet singleton
    iconSet.icons = { cilUser };
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      switch (true) {
        case evt instanceof NavigationStart: {
          this.loader.showLoader()
          break;
        };
          case evt instanceof NavigationEnd:
          case evt instanceof NavigationCancel:
          case evt instanceof NavigationError: {
            this.loader.hideLoader();
            break;
          }
          default: {
            break;
          }
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
