import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from './views/auth/auth.guard';
export const routes: Routes = [

  {
    path:'',
    redirectTo:'dashboard',
    pathMatch:'full'
  },

  {
       path: '',
       component: DefaultLayoutComponent,
       canActivate: [AuthGuard],
       data: {
         title: 'Home'
       },
       children: [{
          path: 'dashboard',
          canActivate: [AuthGuard],
          loadChildren: () => import('./views/dashboard-tiles/dashboard-tiles.module').then(m => m.DashboardTilesModule)
       },
       {
        path:'Tenants',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/tenant/tenant.module').then(m=> m.TenantModule),
        
      },
       {
         path:'Users',
         canActivate: [AuthGuard],
         loadChildren: () => import('./views/users/users.module').then(m=> m.UsersModule),
         
       },
       {
        path:'SvcUser',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/svcuser/svcuser.module').then(m=> m.SvcuserModule),
        
      },
       {
        path:'Groups',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/groups/groups.module').then(m=> m.GroupsModule)
      },
      {
        path:'Roles',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/roles/roles.module').then(m=> m.RolesModule)
      },
      {
        path:'Datacenter',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/datacenter/datacenter.module').then(m=>m.DatacenterModule)
      },
      {
        path:'VM',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/vm/vm.module').then(m=>m.VMModule)
      },
      {
        path:'SAP',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/sap/sap.module').then(m=>m.SapModule)
      },
      {
        path:'DB',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/database/database.module').then(m=>m.DatabaseModule)
      },
      {
        path:'Catalog',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/catalog/catalog.module').then(m=>m.CatalogModule)
      },
      {
        path:'Services',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/services/services.module').then(m=>m.ServicesModule)
      },
      {
        path:'Jobs',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/jobs/jobs.module').then(m=>m.JobsModule)
      },
      {
        path:'Settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./custom/settings/settings.module').then(m=>m.SettingsModule)
      },
      {
        path:'Misc',
        canActivate: [AuthGuard],
        loadChildren: () => import('./custom/misc/misc.module').then(m=>m.MiscModule)
      },
      {
        path:'Chat',
        canActivate: [AuthGuard],
        loadChildren: () => import('./custom/chat/chat.module').then(m=>m.ChatModule)
      },
      {
        path:'Finance',
        canActivate: [AuthGuard],
        loadChildren: () => import('./custom/finance/finance.module').then(m=>m.FinanceModule)
      },
      {
        path:'Email',
        canActivate: [AuthGuard],
        loadChildren: () => import('./custom/email/email.module').then(m=>m.EmailModule)
      } 
      ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
