import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './service/auth.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators'
import { LoaderService } from 'src/app/loader/loader.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService,private loader: LoaderService) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
     return this.auth.addTokenToHeader(request.headers).pipe(
      mergeMap((headersWithBearer) => {
        const kcReq = request.clone({ headers: headersWithBearer });
        return next.handle(kcReq);
      })
     )

    // request = request.clone({
    //   setHeaders: {
    //     Authorization: `Bearer ${this.auth.getbearertoken()}`
    //   }
    // });
    // console.log(this.auth.getbearertoken())
    // console.log('Added the token to header'+ this.auth.getbearertoken())
    // return next.handle(request);
  }

// private handleRequestWithTokenHeader(
//     req: HttpRequest<unknown>,
//     next: HttpHandler
//   ): Observable<HttpEvent<unknown>> {
//     return this.keycloak.addTokenToHeader(req.headers).pipe(
//       mergeMap((headersWithBearer) => {
//         const kcReq = req.clone({ headers: headersWithBearer });
//         return next.handle(kcReq);
//       })
//     );
//   }
}